<template>
  <div class="newscl">
    <!-- header组件     -->
    <vheader :navId="navId"> </vheader>
    <!-- header组件     -->

    <!-- banner组件 -->
    <!-- <banner :navName="navName" :navUrl="navUrl"></banner> -->
    <div class="banner-box">
      <div class="banner-img"></div>
      <div class="banner-nav">
        <div class="banner-nav-content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span><i class="el-icon-location"></i>当前位置：</span>
            <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'mediaCoverage' }" title="媒体报道">媒体报道</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'newscl1' }" title="新闻详情">新闻详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- banner组件 -->
    <div class="newscl_w">
      <div class="newsContentBoxLeft">
        <h1 class="newsContentBoxLeftTitle" id="title">热烈祝贺恒安康泰入选“创客北京2021”海淀区复赛！</h1>
        <p class="newsContentBoxLeftTime">
          <span>发布时间：2021-08-11 09:11:35</span><span>阅读：200</span><span>来自：恒安康泰新闻中心</span><span>编辑：刘春梅</span>
        </p>
        <hr class="newsContentBoxLeftFenxian">
        <div id="frameContent">
          <p style="text-align: center;"><strong>跻身创客组（新型便民）</strong></p>
          <p style="text-align: center;"><strong><img src="../../assets/img/mediaCoverage/1628643675.jpg"
                title="1628643675.jpg" alt="未标题-2(1).jpg" width="635" height="305"></strong></p>
          <p style="text-indent: 2em; line-height: 1.75em;"><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            经过与各参赛企业的激烈角逐，大赛评审组的精心评选，恒安康泰以优质的技术团队及切实的便民社会价值成功入围“创客北京2021"海淀区复赛！恒安康泰将以自主开发的互联网医疗辅助平台为核心，不断深入优质医疗资源对接，在便民利民的社会医疗辅助方面继续努力，不断进取，再创佳绩！
          </p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1628643465.png" title="1628643465.png"
              alt="弄.png"></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1628643473.jpg" title="1628643473.jpg"
              alt="11.jpg" width="575" height="315"></p>
          <p><br></p>
          <p>通知消息</p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            7月22日，“创客北京2021”创新创业大赛海淀区初赛圆满结束。海淀区级赛是在工信部、财政部、市经信局、市财政局的指导下，由中关村科学城管理委员会主办，中关村科技园区海淀园创业服务中心（海淀创业园）承办。</p>
          <p style="text-indent: 2em; line-height: 1.75em;"><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            来自各行业的专家评委汇聚一堂，以公平、公正、公开的原则为参赛项目和企业打分。按照大赛统一规定和要求，经过初赛评选，191个项目脱颖而出，晋级复赛。其中，创客组52个，企业组139个。</p>
          <p><br></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1628643852.jpg" title="1628643852.jpg"
              alt="1127051197.jpg" width="605" height="363"></p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            “创客北京2021”创新创业大赛参赛项目遍布北京市16个行政区和北京经济技术开发区，来自海淀区、顺义区、北京经济技术开发区的项目占总参赛项目的数量的51%。海淀区在医药健康、人工智能、科技服务等领域的项目数量均占据榜首位置。<br>
          </p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            本次大赛启动伊始，中关村科学城管理委员会便高度重视赛事筹办工作，为保障各项工作规范、有序开展，第一时间成立由服务体系建设处和创业服务中心组成的海淀区级赛执行委员会，制定了完备的实施方案，统筹协调、全面指导海淀区各基地、平台开展赛事筹备相关工作。执委会分工明确，分设项目组、评委组、会务组、媒体组4大工作专班，定期召开推进会，实时跟进赛事进展，与各基地、平台建立同步联络机制，设专人每天沟通解答疑问，督导项目征集，统一告知赛事规范、注意事项等。
          </p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            为了让海淀区内的众多科技企业全方位、多角度的了解大赛。大赛执委会全面动员海淀区47个平台、基地积极开展大赛宣传和项目征集工作，制作并发布大赛的宣传物料张贴在各大楼宇，在各大网站、公众号、媒体平台等实时更新大赛通知及热点动态，迅速得到广大创业者关注和支持。各平台和基地也在第一时间动员园区内的企业踊跃报名参赛，并重点推荐高水平、高质量、自主原创的科技创新创业项目积极参赛。自6月22日至7月16日，仅不到一个月的时间，在大家的共同努力下，“创客北京2021”创新创业大赛海淀区级赛共征集项目549个，其中，创客组184个，企业组365个。海淀创业园发动征集项目115个，占全区比例21%，其中，创客组48个，企业组67个。
          </p>
          <p><br></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1628643889.jpg" title="1628643889.jpg"
              alt="1628643889.jpg" width="604" height="342"></p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            海淀区级复赛将于8月10日-13日在海淀创业园举办。结合疫情防控要求，本次复赛将采用视频会议和网络直播相结合的方式线上进行。在精心组织区级赛事的同时，海淀区级赛执委会坚持“服务”为本，积极整合各类创新创业服务资源，给予获奖企业和项目在创业培训、人才代办、产业对接、专项申报、知识产权、高新认定、财税法务等方面对接更多、更有效的支持和服务。对于成功进入复赛的企业，更可免费帮助其申请登陆北京股权交易中心(北京四板市场)。赛后，海淀区级赛执委会还将举办专场投资对接活动，重点帮助获奖企业及项目对接众多知名投资机构，解决疫情期间的企业资金链难题。同时，在赛前、赛中、赛后各阶段，全程邀请知名媒体对大赛及参赛项目作重点宣传和推介。
          </p>
          <p><br></p>
          <p style="text-indent: 2em; line-height: 1.75em;">
            在即将进行的“创客北京2021”创新创业大赛海淀区复赛阶段，海淀区级赛执委会将着力做好赛中、赛后的全链条服务，保障大赛顺利进行，推动优质企业脱颖而出。海淀区作为全市乃至全国的科技创新出发地、原始创新策源地、自主创新主阵地，将通过本次大赛挖掘出高水平、高层次、高素质的创业团队和具有核心竞争能力的高成长性创新企业，激发区域创新潜力！
          </p>
          <p><br></p>
          <p>转自：海淀创业园官方新闻<br></p>
          <p><br></p>
          <p style="text-align: center;"><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
        </div>
      </div>
      <div class="listNewsRight">
        <h2 class="stateNewsRighth2">健康热点资讯</h2>
        <hr class="stateNewsRight_h2_hr" />
        <h3 class="stateNewsRighth3">
          <a href="" title="国务新闻">国务新闻</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[0]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <!-- <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu1.jpg" alt="" title="恒安康泰医疗" /></a> -->
        <h3 class="stateNewsRighth3">
          <a href="" title="名医出诊">名医出诊</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[1]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康养生">健康养生</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[2]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu2.jpg" alt="" title="恒安康泰医疗" /></a>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康资讯">健康资讯</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[3]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
      </div>
    </div>
    <vfooter></vfooter>
    <GoTop />
  </div>
</template>
<script>
// import banner from "../../components/banner.vue";
import vheader from "../../components/vheader.vue";
import vfooter from "../../components/vfooter.vue";
import GoTop from "@/components/GoTop.vue";
export default {
  name: 'newscl7',
  data() {
    return {
      navName: '新闻详情',
      navUrl: 'newscl7',
      navId: 5,
      rilist1: [
        [
          { listit: "2020年全国居民健康素养水平达23.15%", lishref: "#" },
          { listit: "癌症治疗新希望！我国首个自主研发...", lishref: "#" },
          { listit: "国家卫健委出台人感染动物源性流感...", lishref: "#" },
          { listit: "国家医保局发文，明确推进门诊费用...", lishref: "#" },
          { listit: "国家卫健委：加强儿童中医药服务 推...", lishref: "#" },
          { listit: "这家西南的医院用高品质医疗服务，建...", lishref: "#" },
          { listit: "“十四五”残疾人康复服务蓝图绘就", lishref: "#" },
          { listit: "《2021医生互联网诊疗行为洞察报告》...", lishref: "#" },
          { listit: "六大方面布局！国办发文推动公立医院...", lishref: "#" },
          { listit: "七大城市用户调研指出：智慧医疗未来...", lishref: "#" },
        ],
        [
          { listit: "中国人民解放军总医院院士合集（部分）", lishref: "#" },
          { listit: "U医公益行启动，让泌尿疾病患者在基层...", lishref: "#" },
          { listit: "第十八期“院士+”西部儿科医师培训班座...", lishref: "#" },
          { listit: "福建首例机器人无腹部切口大肠癌手术在...", lishref: "#" },
          { listit: "多位专家赴湖北十堰参加燃气爆炸紧急医...", lishref: "#" },
          { listit: "张楠：阿尔茨海默病患者护理更需专业指导", lishref: "#" },
          { listit: "第一届智慧医疗与医学人文高峰论坛暨医...", lishref: "#" },
          { listit: "提醒身边长痣的朋友，出现以下五种症状...", lishref: "#" },
          { listit: "“疫”路同行的夫妻档", lishref: "#" },
          { listit: "与北京社团合作，中国西部城市乌兰察布...", lishref: "#" },
        ],
        [
          { listit: "水培蔬菜社交网络走红 专家：较土培更...", lishref: "#" },
          { listit: "山药的做法 山药的功效与作用", lishref: "#" },
          { listit: "发青的鸡蛋黄致癌？ 变绿部分无毒，可...", lishref: "#" },
          { listit: "经常失眠，可能是体内缺什么？3种营养...", lishref: "#" },
          { listit: "你知道健康吃饭的黄金标准吗？", lishref: "#" },
          { listit: "5大习惯能『防癌』，很多人都忽视了", lishref: "#" },
          { listit: "每餐4-6小时的间隔期最符合人体消化需...", lishref: "#" },
          { listit: "晨起一杯水别这么喝，风险太大...教你...", lishref: "#" },
          { listit: "心脏有问题，为什么医生还建议让我多运...", lishref: "#" },
          { listit: "缺铁性贫血，补就完事？补血食物别踩坑", lishref: "#" },
        ],
        [
          { listit: "疾控专家邵一鸣对我国目前疫苗功效给出解读", lishref: "#" },
          {
            listit: "馒头、米饭哪个更容易升血糖？一对比，3餐...",
            lishref: "#",
          },
          { listit: "手抖也能通过外科手术治愈啦", lishref: "#" },
          { listit: "身体血糖高 多喝水好还是少喝水好？看完...", lishref: "#" },
          { listit: "练起来 打太极拳能减“游泳圈”", lishref: "#" },
          { listit: "【科学就医我行动】陈勇皮科问答 | 10.皮...", lishref: "#" },
          { listit: "维生素C有什么功效和副作用？", lishref: "#" },
          {
            listit: "“提高免疫力”，被过度夸大化，免疫平衡比“...",
            lishref: "#",
          },
          { listit: "胃癌如何选择最合适疗方案？CA最新刊文指...", lishref: "#" },
          { listit: "九价HPV疫苗为何“一针难求”", lishref: "#" },
        ],
      ],
    };
  },
  components: {
    // banner,
    vheader,
    vfooter,
    GoTop,
  },
};
</script>
<style lang="less">
@media screen and (min-width:750px) {
  .banner-box {
    .banner-img {
      height: 280Px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 1200Px;
        margin: auto;

        .el-breadcrumb {
          line-height: 70Px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 20Px;
            margin-right: 12Px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .banner-box {
    .banner-img {
      height: 110px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 95%;
        margin: auto;

        .el-breadcrumb {
          line-height: 35px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 19px;
            margin-right: 2px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}
</style>